import { useHistory } from 'react-router-dom'
import { Stack, Button, Center, Spinner, Heading } from '@chakra-ui/react'
import { useLoadingAndError, useQuestion } from '../../../contexts/FormContext'
import {
  useResetAnswers,
  useAnswers,
  useIdGroup,
  useInvalid,
  usePostAnswers,
  useFormVocational,
} from '../../../contexts/FormContext/FormProvider'
import QuestionList from './Question'
import IconCancel from '../../Atoms/IconSvg/IconCancel'
import { postAnswers } from '../../../api/endpoint'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import keySessionStorage from '../../../config/keySessionStorage'
import { useHandlerUser } from '../../../contexts/UserContext/UserProvider'

export const Form = () => {
  const questions = useQuestion()
  const [loading, error] = useLoadingAndError()
  const [answers] = useAnswers()
  const [groupId, setGroupId] = useIdGroup()
  const [, setIsInvalid] = useInvalid()
  const navigation = useHistory()
  const handleReset = useResetAnswers()
  const willPost = usePostAnswers()
  const setLoading = useFormVocational()[11]
  const setError = useFormVocational()[12]
  const resetUser = useHandlerUser()[2]
  const [, setResult] = useSessionStorage(
    keySessionStorage.resultVocational,
    'NaN'
  )

  const handleNext = (event, val) => {
    event.preventDefault()
    let valid = {}
    let validator = true
    document.querySelector('#root').scrollTo(0, 0)
    Object.entries(answers).forEach((current) => {
      valid = {
        ...valid,
        [current[0]]: current[1] === '',
      }

      if (current[1] === '') validator = false
    })
    setIsInvalid(valid)
    if (validator) {
      setGroupId(val)
      handleReset()
      val === 8 && handleSubmit()
    }
  }

  const handleSubmit = async () => {
    try {
      setError(false)
      setLoading(true)
      const { data } = await postAnswers({
        ...willPost,
        data: [...willPost.data, { idGrupo: groupId, ...answers }],
      })
      console.log(data, 'data send', [
        ...willPost.data,
        { idGrupo: groupId, ...answers },
      ])
      setLoading(false)
      setResult(data)
      resetUser()
      navigation.push('/finished')
    } catch {
      setLoading(false)
      setError(true)
      console.log('has been error')
    }
  }

  return (
    <form>
      {loading ? (
        <Center pt="10">
          <Spinner size="lg" />
        </Center>
      ) : error ? (
        <Stack pt="7">
          <Heading>Ha sucedido un error inesperado</Heading>
          <IconCancel width="90%" style={{ marginTop: -80 }} />
        </Stack>
      ) : (
        <>
          <QuestionList questions={questions} />
          <Stack direction="row" justifyContent="flex-end">
            <Button
              type={groupId <= 7 ? 'button' : 'submit'}
              variant="outline"
              onClick={(event) => handleNext(event, groupId + 1)}
            >
              {groupId < 7 ? 'Siguiente' : 'Finalizar'}
            </Button>
          </Stack>
        </>
      )}
    </form>
  )
}
