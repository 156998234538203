import { useState } from 'react'
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Box,
  Button,
  Text,
} from '@chakra-ui/react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { useHistory } from 'react-router'
import expressions from '../../../config/expresionsRegulars'
import { postLoginAdmin } from '../../../api/endpoint'
import { useToken } from '../../../hooks/useToken'
import { useHandlerUser } from '../../../contexts/UserContext/UserProvider'

export const Login = () => {
  const [show, setShow] = useState(false)
  const [user, setUser] = useState({ email: '', password: '' })
  const [errorDigit, setErrorDigit] = useState({
    email: false,
    password: false,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const navigation = useHistory()
  const handleTokenAdmin = useToken()[1]
  const setToken = useHandlerUser()[4]

  const handlePassword = () => setShow(!show)

  const handleChange = ({ target: { value, name } }) => {
    let validator

    setUser({ ...user, [name]: value })
    if (name === 'email') validator = !expressions.email.test(value)
    setErrorDigit({ ...errorDigit, [name]: validator })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (
      !errorDigit.email &&
      !errorDigit.password &&
      user.email !== '' &&
      user.password !== ''
    ) {
      try {
        setLoading(true)
        setError(false)
        const {
          data: { token },
        } = await postLoginAdmin(user)
        setLoading(false)
        handleTokenAdmin(token)
        setToken(token)
        navigation.push('/admin/results')
      } catch {
        setLoading(false)
        setError(true)
      }
    } else {
      setErrorDigit({
        email: user.email === '',
        password: user.password === '',
      })
    }
  }

  return (
    <>
      <Heading>Login</Heading>
      <Box pt="6">
        <form>
          <FormControl paddingBottom="5">
            <FormLabel>Email</FormLabel>
            <Input
              size="md"
              placeholder="email"
              isInvalid={errorDigit.email}
              name="email"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl paddingBottom="5">
            <FormLabel>password</FormLabel>
            <InputGroup>
              <Input
                pr="3rem"
                size="md"
                name="password"
                isInvalid={errorDigit.password}
                onChange={handleChange}
                type={show ? 'text' : 'password'}
                placeholder="Enter the password"
              />
              <InputRightElement width="3rem">
                {show ? (
                  <IconButton
                    aria-label=""
                    h="1.75rem"
                    size="sm"
                    onClick={handlePassword}
                    type="button"
                    icon={<FiEyeOff />}
                  />
                ) : (
                  <IconButton
                    aria-label=""
                    h="1.75rem"
                    size="sm"
                    type="button"
                    onClick={handlePassword}
                    icon={<FiEye />}
                  />
                )}
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl d="flex" justifyContent="center">
            <Button
              onClick={handleSubmit}
              colorScheme="brandGreen"
              variant="solid"
              type="submit"
              isLoading={loading}
            >
              Iniciar sesión
            </Button>
          </FormControl>
          {error && (
            <Text textAlign="center" pt="4">
              ha sucedido un error inesperado
            </Text>
          )}
        </form>
      </Box>
    </>
  )
}
