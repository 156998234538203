import { useState } from 'react'
import keys from '../config/keyLocalStorage'

export const useToken = () => {
  // redirect to home or admi/login when the token was kill
  const [token, setToken] = useState(() => {
    try {
      const item = localStorage.getItem(keys.tokenUId)
      return item !== null ? item : null
    } catch {
      return null
    }
  })

  const handleSetToken = (value) => {
    try {
      localStorage.setItem(keys.tokenUId, value)
      setToken(value)
    } catch {
      setToken(null)
      console.log('error to change the token')
    }
  }

  const handleRemove = () => {
    try {
      localStorage.removeItem(keys.tokenUId)
      setToken(null)
    } catch {
      console.log('error to remove the token')
    }
  }

  return [token, handleSetToken, handleRemove]
}
