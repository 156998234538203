import { HStack, Box } from '@chakra-ui/react'

export const ContainerLogin = ({ children }) => {
  return (
    <HStack
      w="100%"
      flexDirection="column"
      minHeight="100vh"
      justifyContent="center"
    >
      <Box
        borderWidth="1px"
        borderRadius="md"
        p="5"
        w="400px"
        minHeight="55vh"
        backgroundColor="white"
      >
        {children}
      </Box>
    </HStack>
  )
}
