import React, { useState, useEffect, useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from '../../pages/Home'
import Login from '../../pages/Login'
import FinishedForm from '../../pages/FinishedForm'
import NotFound from '../../pages/NotFound'
import PollStudents from '../../pages/PollStudents'
import Dashboard, { AdminLogin } from '../../pages/Admin'
import keySessionStorage from '../../config/keySessionStorage'
import { useSessionStorage } from '../../hooks/useSessionStorage'
import { useToken } from '../../hooks/useToken'

const UserContext = React.createContext()

export const UserProvider = () => {
  const [_, setter, handleRemove] = useSessionStorage(
    keySessionStorage.keyUser,
    null
  )
  const [dataUser, setDataUser] = useState(() => {
    try {
      const item = sessionStorage.getItem(keySessionStorage.keyUser)
      return item === null ? null : JSON.parse(item)
    } catch {
      return null
    }
  })
  const [token] = useToken()
  const [currentToken, setCurrentToken] = useState(token)

  const handleSession = (value) => {
    setter(value)
    setDataUser(value)
  }

  const resetUserSession = () => {
    handleRemove()
    setDataUser(null)
  }

  useEffect(() => {
    token && setCurrentToken(token)
  }, [token])

  return (
    <UserContext.Provider
      value={[
        dataUser,
        handleSession,
        resetUserSession,
        currentToken,
        setCurrentToken,
      ]}
    >
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/login">
          {dataUser ? <Redirect to="/poll-students" /> : <Login />}
        </Route>
        <Route exact path="/poll-students">
          {dataUser ? <PollStudents /> : <Redirect to="/login" />}
        </Route>
        <Route path="/finished">
          <FinishedForm />
        </Route>
        <Route exact path="/admin/login">
          {!currentToken ? <AdminLogin /> : <Redirect to="/admin/results" />}
        </Route>
        <Route exact path="/admin/results">
          {currentToken ? <Dashboard /> : <Redirect to="/admin/login" />}
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </UserContext.Provider>
  )
}

export const useHandlerUser = () => useContext(UserContext)
