import { Helmet } from 'react-helmet-async'
import FinishedForm from '../../components/Molecules/AdminResults'
import { FilterProvider } from '../../contexts/FilterContext/FilterProvider'

export const Dasboard = () => {
  return (
    <>
      <Helmet>
        <title>Dasboard</title>
      </Helmet>
      <FilterProvider>
        <FinishedForm />
      </FilterProvider>
    </>
  )
}
