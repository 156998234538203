import styled from 'styled-components'

const HeaderContent = styled.header`
  width: 100%;
  height: 400px;
  background-image: url('/assets/test-vocacional-2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: inherit;
`

export const Header = () => {
  return <HeaderContent />
}
