import { Box } from '@chakra-ui/react'

export const BackgroundLayout = () => {
  return (
    <>
      <Box
        w="500px"
        h="500px"
        left="-25%"
        top="15%"
        borderWidth="1px"
        borderRadius="50%"
        position="fixed"
        zIndex="-9"
        borderColor="green.400"
      />
      <Box
        w="35%"
        h="80vh"
        right="-25%"
        bottom="-20%"
        borderWidth="1px"
        borderRadius="50%"
        position="fixed"
        zIndex="-9"
        borderColor="green.400"
      />
    </>
  )
}
