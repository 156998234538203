import React, { useState, useEffect, useContext } from 'react'
import { getAllQuiestions } from '../../api/endpoint'
import { useHandlerUser } from '../UserContext/UserProvider'

export const FormContext = React.createContext()

// change to array for send the all content to backend
export const modelAnswers = {
  uno: '',
  dos: '',
  tres: '',
  cuatro: '',
  cinco: '',
}

export const FormProvider = ({ children }) => {
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState(modelAnswers)
  const [willPost, setWillPost] = useState({ idApplicant: '', data: [] })
  const [idGroupQuestions, setIdGroupQuestions] = useState(1)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [isInvalid, setIsInvalid] = useState({})
  const userData = useHandlerUser()[0]

  const handleChangeGroup = (val) => setIdGroupQuestions(val)

  const handleSetAnswers = ({ name, value }) =>
    setAnswers({ ...answers, [name]: value })

  const handleResetAnswers = () => {
    setIsInvalid({})
    setWillPost({
      ...willPost,
      idApplicant: userData.id,
      data: [...willPost.data, { idGrupo: idGroupQuestions, ...answers }],
    })
    setAnswers(modelAnswers)
  }

  const getQuestions = async () => {
    try {
      setError(false)
      setLoading(true)
      const { data } = await getAllQuiestions(idGroupQuestions)
      setLoading(false)
      setQuestions(data)
    } catch {
      setLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    idGroupQuestions < 8 && getQuestions()
  }, [idGroupQuestions])

  return (
    <FormContext.Provider
      value={[
        questions,
        answers,
        idGroupQuestions,
        loading,
        error,
        handleChangeGroup,
        handleSetAnswers,
        isInvalid,
        setIsInvalid,
        handleResetAnswers,
        willPost,
        setLoading,
        setError,
      ]}
    >
      {children}
    </FormContext.Provider>
  )
}

const useFormVocational = () => useContext(FormContext)

const useQuestion = () => useContext(FormContext)[0]

const useAnswers = () => {
  const ans = useContext(FormContext)[1]
  const set = useContext(FormContext)[6]
  return [ans, set]
}

const useIdGroup = () => {
  const id = useContext(FormContext)[2]
  const set = useContext(FormContext)[5]
  return [id, set]
}

const useLoadingAndError = () => {
  const loading = useContext(FormContext)[3]
  const error = useContext(FormContext)[4]
  return [loading, error]
}

const useInvalid = () => {
  const isInvalid = useContext(FormContext)[7]
  const setIsInvalid = useContext(FormContext)[8]
  return [isInvalid, setIsInvalid]
}

const useResetAnswers = () => useContext(FormContext)[9]
const usePostAnswers = () => useContext(FormContext)[10]

export {
  useFormVocational,
  useQuestion,
  useAnswers,
  useIdGroup,
  useLoadingAndError,
  useInvalid,
  useResetAnswers,
  usePostAnswers,
}
