import { Helmet } from 'react-helmet-async'
import { ContainerLogin, Login } from '../../components/Molecules/Session'

export const AdminLogin = () => {
  return (
    <>
      <Helmet>
        <title>login Admin</title>
      </Helmet>
      <ContainerLogin>
        <Login />
      </ContainerLogin>
    </>
  )
}
