import { Helmet } from 'react-helmet-async'
import { Heading } from '@chakra-ui/react'

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Not Found - Page</title>
      </Helmet>
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Heading>the server has not found your url that wish access</Heading>
      </div>
    </>
  )
}
