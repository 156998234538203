import { useState } from 'react'
import { Text, RadioGroup, Radio, FormControl, Stack } from '@chakra-ui/react'
import {
  modelAnswers,
  useAnswers,
  useInvalid,
} from '../../../../contexts/FormContext'

export const Question = ({ index = 0, question = '', id = 0 }) => {
  const [value, setValue] = useState()
  const [_, setAnswers] = useAnswers()
  const [isInvalid, setIsInvalid] = useInvalid()
  const name = Object.entries(modelAnswers)[index][0]

  const handleChange = (change) => {
    setValue(change)
    setAnswers({ name, value: change })
    setIsInvalid({ ...isInvalid, [name]: false })
  }

  return (
    <FormControl pb="7">
      <Text fontSize="lg" pb="4">
        {id}. {question}
      </Text>
      <RadioGroup onChange={handleChange} value={value}>
        <Stack direction="row" spacing="4">
          <Radio value="1" colorScheme="green" isInvalid={isInvalid[name]}>
            Si
          </Radio>
          <Radio value="0" colorScheme="green" isInvalid={isInvalid[name]}>
            No
          </Radio>
        </Stack>
      </RadioGroup>
    </FormControl>
  )
}
