import {
  Text,
  Wrap,
  WrapItem,
  LinkBox,
  Box,
  Heading,
  LinkOverlay,
  Link,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const ResultFinishForm = ({
  programs = [],
  group = '',
  description = '',
}) => (
  <>
    <Text fontSize="4xl">{group}</Text>
    <Text fontSize="2xl" textAlign="center">
      {description}
    </Text>
    <Wrap w="100%" p="2" spacing="20px" justify="center">
      {programs.map(({ name = '', link = '' }, index) => (
        <WrapItem
          key={index}
          borderWidth="1px"
          w={screenX < 768 ? '100%' : '30%'}
          borderRadius="4px"
          borderColor="green.400"
        >
          <LinkBox w="100%" h="100%" p="2">
            <Box>
              <Heading size="sm" mt="2" mb="4" lineHeight="1">
                <LinkOverlay href={link} isExternal>
                  {name}
                </LinkOverlay>
              </Heading>
              <Link fontSize="sm" href={link} isExternal>
                La CORHUILA ofrece estos programas para ti. Has click aquí para
                ver más
                <ExternalLinkIcon mx="2" />
              </Link>
            </Box>
          </LinkBox>
        </WrapItem>
      ))}
    </Wrap>
  </>
)
