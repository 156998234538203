import axios from 'axios'
import configAxios from './configAxios'
import { API_URL } from './urls'

// test local-api

// post userData
export const postUserData = (user) =>
  axios.post(`${API_URL}/register`, {
    full_name: user.fullName,
    email: user.email,
    phone_number: user.phone,
    data_processing: user.isChecked,
  })

// get questions
export const getAllQuiestions = (groupId) =>
  axios.get(`${API_URL}/questions/${groupId}`)

// post the answers
export const postAnswers = (answers) => axios.post(`${API_URL}/replay`, answers)

// [ADMIN]
// admin [get the results]

export const postLoginAdmin = (credentials) =>
  axios.post(`${API_URL}/data`, credentials)

export const getAllResults = ({ group = '', token }) =>
  axios.get(`${API_URL}/info/replay?group=${group}`, configAxios(token))

export const getAllGroups = (token) =>
  axios.get(`${API_URL}/group`, configAxios(token))

export const downloadExcel = (group = '', token) =>
  axios.get(`${API_URL}/export?group=${group}`, {
    decompress: true,
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
