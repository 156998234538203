import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  Box,
  Button,
  Checkbox,
} from '@chakra-ui/react'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import { postUserData } from '../../../api/endpoint'
import key from '../../../config/keySessionStorage'
import expressions from '../../../config/expresionsRegulars'
import { useHandlerUser } from '../../../contexts/UserContext/UserProvider'

export const Register = () => {
  const [user, setUser] = useState({ fullName: '', email: '', phone: '' })
  const [isInvalid, setIsInvalid] = useState({
    fullName: false,
    email: false,
    phone: false,
  })
  const [isChecked, setIsChecked] = useState(false)
  const [errCheckBox, setErrCheckBox] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [_, setSession] = useSessionStorage(key.keyUser, null)
  const navigation = useHistory()
  const [__, setDataUser] = useHandlerUser()

  const handleChange = ({ target: { name, value } }) => {
    let validator

    setUser({ ...user, [name]: value })
    if (name === 'fullName') validator = !expressions.name.test(value)
    else if (name === 'email') validator = !expressions.email.test(value)
    else validator = !expressions.phone.test(value)
    setIsInvalid({ ...isInvalid, [name]: validator })
  }

  const handleCheck = () => {
    setIsChecked(!isChecked)
    setErrCheckBox(isChecked)
  }

  const handleSubmit = async (e) => {
    const cond = user.fullName !== '' && user.email !== '' && user.phone !== ''
    if (
      !isInvalid.fullName &&
      !isInvalid.email &&
      !isInvalid.phone &&
      cond &&
      isChecked
    ) {
      try {
        e.preventDefault()
        setError(false)
        setLoading(true)
        const {
          data: { message },
        } = await postUserData({ ...user, isChecked })
        setLoading(false)
        setSession(message)
        setDataUser(message)
        navigation.push('/poll-students')
      } catch {
        setLoading(false)
        setError(true)
      }
    } else {
      setIsInvalid({
        fullName: user.fullName === '',
        email: user.email === '',
        phone: user.phone === '',
      })
      setErrCheckBox(!isChecked ? true : !isChecked)
    }
  }

  return (
    <>
      <Heading>Register</Heading>
      <Box pt="6">
        <FormControl paddingBottom="5">
          <FormLabel>Nombre completo</FormLabel>
          <Input
            size="md"
            name="fullName"
            placeholder="full name"
            value={user.fullName}
            onChange={handleChange}
            errorBorderColor="crimson"
            isInvalid={isInvalid.fullName}
            focusBorderColor={isInvalid.fullName ? 'crimson' : 'green'}
          />
        </FormControl>
        <FormControl paddingBottom="5">
          <FormLabel>Correo electrónico</FormLabel>
          <Input
            size="md"
            name="email"
            placeholder="email"
            value={user.email}
            onChange={handleChange}
            errorBorderColor="crimson"
            isInvalid={isInvalid.email}
            focusBorderColor={isInvalid.email ? 'crimson' : 'green'}
          />
        </FormControl>
        <FormControl paddingBottom="5">
          <FormLabel>Número telefonico</FormLabel>
          <Input
            size="md"
            name="phone"
            placeholder="phone"
            value={user.phone}
            onChange={handleChange}
            errorBorderColor="crimson"
            isInvalid={isInvalid.phone}
            focusBorderColor={isInvalid.phone ? 'crimson' : 'green'}
          />
        </FormControl>
        <FormControl paddingBottom="5">
          <Checkbox
            onChange={handleCheck}
            value={isChecked}
            isInvalid={errCheckBox}
            textAlign="justify"
          >
            Aceptas los terminos y condiciones para tratar tus datos personales
          </Checkbox>
        </FormControl>
        <FormControl d="flex" justifyContent="center">
          <Button
            isLoading={loading}
            onClick={handleSubmit}
            colorScheme="brandGreen"
            variant="solid"
          >
            Registrarse
          </Button>
        </FormControl>
        {error && 'has been any error'}
      </Box>
    </>
  )
}
