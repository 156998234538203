import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { Box, Button, Heading, HStack, Text } from '@chakra-ui/react'

export const Home = () => {
  const navigation = useHistory()

  const handleNavigation = () => navigation.push('/login')

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <HStack justifyContent="center" alignItems="center" w="100%">
        <Box w="90%" p="4" d="flex" flexDirection="column" alignItems="center">
          <Heading textAlign="center" marginBottom="30px" fontSize="5xl">
            Bienvenido al test de intereses y aptitudes vocacionales
          </Heading>
          <Text textAlign="center" mb="20px">
            El presente test pretente acercarlo a los difeerentes grupos
            ocupacionales y pueda analizar las preeferencias, gustos, y
            habilidades en el ámbito profesional
          </Text>
          <Button
            colorScheme="brandGreen"
            padding="15px 60px"
            variant="solid"
            onClick={handleNavigation}
          >
            Iniciar test
          </Button>
        </Box>
      </HStack>
    </>
  )
}
