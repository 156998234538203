import { Button, Center, HStack, Spinner, Text } from '@chakra-ui/react'
import {
  useFilter,
  useFilterAll,
  useLoadingDownload,
  useLoadingFilter,
  usePaginate,
  useTableFilter,
} from '../../../contexts/FilterContext'
import { AdminTable } from './AdminTable'
import { FormFilterResult } from './FormFilterResult'

export const AdminResults = () => {
  const groups = useFilterAll()[5]
  const handleDownload = useFilterAll()[10]
  const getTableResult = useFilterAll()[11]
  const [paginate, navPaginate] = usePaginate()
  const [filter, setFilter] = useFilter()
  const tableData = useTableFilter()
  const [loading, error] = useLoadingFilter()
  const [loadDownload, errDownload] = useLoadingDownload()

  return (
    <AdminContainer
      isLoading={loading}
      isError={error}
      onClick={getTableResult}
    >
      <FormFilterResult
        group={filter.group}
        onChange={setFilter}
        groups={groups}
      />
      <AdminTable
        tableData={tableData}
        paginate={paginate}
        navPaginate={navPaginate}
        stateDownload={{ loadDownload, errDownload }}
        onClick={handleDownload}
      />
    </AdminContainer>
  )
}

export const AdminContainer = ({
  children,
  isLoading = true,
  isError = false,
  onClick,
}) => (
  <HStack
    w="100%"
    minHeight="100vh"
    flexDir="column"
    alignContent="center"
    justifyContent="center"
    pt="40px"
    pb="40px"
  >
    {isLoading ? (
      <Spinner size="md" />
    ) : isError ? (
      <Center flexDirection="column">
        <Text>Ha ocurrido un error inesperado</Text>
        <Button mt="4" onClick={onClick}>
          Volver a intentarlo
        </Button>
      </Center>
    ) : (
      children
    )}
  </HStack>
)
