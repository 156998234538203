import { useState, useEffect } from 'react'
import { Stack, HStack, Progress, Text } from '@chakra-ui/react'
import { useIdGroup, useLoadingAndError } from '../../../contexts/FormContext'
import { useContextPrincipal } from '../../../contexts/PrincipalContext/PrincipalProvider'

export const ProgressForm = () => {
  const [loading, error] = useLoadingAndError()
  // const { bgProgress } = useContextPrincipal()
  const [groupId] = useIdGroup()

  useEffect(() => {})

  return loading && groupId === 1 ? (
    ''
  ) : error ? (
    ''
  ) : (
    <HStack borderWidth="1px" p="4" mt="10" mb="10" borderRadius="md">
      <Stack w="100%" position="relative">
        <Progress
          colorScheme="green"
          size="lg"
          max="6"
          isAnimated
          value={groupId - 1}
          borderRadius="sm"
        />
        {/* <HStack
          w="100%"
          top="-10px"
          left="0"
          pr="-4px"
          pl="-4px"
          marginTop="0 !important"
          position="absolute"
          justifyContent="space-evenly"
        >
          <ItemProgressList bg={bgProgress} />
        </HStack> */}
      </Stack>
    </HStack>
  )
}

const ItemProgressList = ({ bg }) => {
  const group = ['', '', '', '', '']

  return group.map((_, index) => (
    <Stack key={index} w="40px" h="40px" bg={bg} borderRadius="50%">
      <Text
        fontSize="x-large"
        textAlign="center"
        color="white"
        fontFamily="Raleway"
      >
        {index + 1}
      </Text>
    </Stack>
  ))
}
