import { ChakraProvider } from '@chakra-ui/react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router } from 'react-router-dom'
import PrincipalLayout from './components/Layout/PrincipalLayout'
import PrincipalProvider from './contexts/PrincipalContext'
import BackgroundLayout from './components/Layout/BackgroundLayout'
import UserProvider from './contexts/UserContext'
import theme from './config/theme'

import './App.css'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <HelmetProvider>
        <Router>
          <PrincipalProvider>
            <PrincipalLayout>
              <UserProvider />
            </PrincipalLayout>
          </PrincipalProvider>
          <BackgroundLayout />
        </Router>
      </HelmetProvider>
    </ChakraProvider>
  )
}

export default App
