import { Helmet } from 'react-helmet-async'
import { Stack } from '@chakra-ui/react'
import Form, { ProgressForm } from '../../components/Molecules/Form'
import FormProvider from '../../contexts/FormContext'

export const PollStudents = () => {
  return (
    <>
      <Helmet>
        <title>occupational vocational</title>
      </Helmet>
      <Stack
        w="100%"
        minHeight="100vh"
        paddingLeft="15%"
        paddingRight="15%"
        pb="20"
      >
        <FormProvider>
          <ProgressForm />
          <Form />
        </FormProvider>
      </Stack>
    </>
  )
}
