import { Flex, Box } from '@chakra-ui/layout'
import Header from '../../Molecules/Header'
import Footer from '../../Molecules/Footer'

export const PrincipalLayout = ({ children }) => {
  return (
    <>
      <Box
        w="100%"
        height="100%"
        bgImage="url('/assets/bg.jpg')"
        opacity=".05"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        position="fixed"
        top="0%"
        zIndex="-1"
      />
      <Header />
      <Flex
        maxWidth="1200px"
        marginInlineStart="auto"
        marginInlineEnd="auto"
        minH="100vh"
      >
        {children}
      </Flex>
      <Footer />
    </>
  )
}
