import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
  },
  colors: {
    brandGreen: {
      500: '#2BBC28',
      600: '#2fa128',
      700: '#219128',
    },
  },
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
  styles: {
    global: () => ({
      // 'a, p, button, h1, h2, h3, h4': {
      //   userSelect: 'none',
      // },
    }),
  },
  fonts: {
    heading: 'Open Sans',
    body: 'Raleway',
  },
})

export default theme
