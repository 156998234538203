export const useDownloadExcel = () => {
  const getExcel = (contenidoEnBlob, nombreArchivo) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      const anchor = document.createElement('a')
      anchor.href = event.target.result
      anchor.target = '_blank'
      anchor.download = nombreArchivo || 'orientationVocational.xls'

      const clicEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })

      anchor.dispatchEvent(clicEvent)
      ;(window.URL || window.webkitURL).revokeObjectURL(anchor.href)
    }

    reader.readAsDataURL(contenidoEnBlob)
  }

  return { getExcel }
}
