import { useState } from 'react'

export const useSessionStorage = (key, initialValue) => {
  const [item, setItem] = useState(() => {
    try {
      const item = sessionStorage.getItem(key)
      return item !== null ? JSON.parse(item) : initialValue
    } catch {
      return initialValue
    }
  })

  const handleChange = (value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value))
      setItem(value)
    } catch {
      console.log('eror session-storage')
    }
  }

  const handleRemove = () => {
    try {
      sessionStorage.removeItem(key)
      setItem(initialValue)
    } catch {
      console.log('eror session-storage')
    }
  }

  return [item, handleChange, handleRemove]
}
