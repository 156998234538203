import {
  HStack,
  FormControl,
  FormLabel,
  Select,
  Box,
  Button,
} from '@chakra-ui/react'
import styled from 'styled-components'
import { useFilterAll } from '../../../contexts/FilterContext'

export const FormFilterResult = ({
  group = '',
  groups = [{ name: '', id: 0 }],
  onChange,
}) => {
  const handleFilter = useFilterAll()[11]

  const handleSubmit = (e) => {
    e.preventDefault()
    handleFilter()
  }

  return (
    <HStack w="100%" justifyContent="center">
      <Box w="100%" mb="5" pb="4">
        <Form>
          <FormControl d="flex" flexDir="row" alignContent="center" pr="4">
            <FormLabel m="0 !important" alignSelf="center" pr="2">
              Grupos:
            </FormLabel>
            <Select
              name="group"
              value={group}
              onChange={onChange}
              placeholder="ocupacion vocacional"
            >
              {groups.map(({ name = '', id = 0 }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl d="flex" flexDir="row" alignContent="center">
            <Button variant="outline" onClick={handleSubmit}>
              Search
            </Button>
          </FormControl>
        </Form>
      </Box>
    </HStack>
  )
}

const Form = styled.form`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 10px;

    & div {
      margin-bottom: 5px;
      justify-content: space-between;
    }
  }
`
