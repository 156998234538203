import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import configAxios from '../../api/configAxios'
import { downloadExcel, getAllGroups, getAllResults } from '../../api/endpoint'
import { useDownloadExcel } from '../../hooks/useDownloadExcel'
import { useToken } from '../../hooks/useToken'

export const FitlerContext = React.createContext()

export const FilterProvider = ({ children }) => {
  const [filter, setFilter] = useState({ group: '' })
  const [tableData, setTableData] = useState([])
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [loadDownload, setLoadDownload] = useState(false)
  const [errDownload, setErrDownload] = useState(false)
  const { getExcel } = useDownloadExcel()
  const [paginate, setPaginate] = useState({
    next: '',
    prev: '',
  })
  const [token] = useToken()

  const handleChangeFilter = ({ target: { name, value } }) =>
    setFilter({ ...filter, [name]: value })

  const navPaginate = async (url) => {
    try {
      setLoading(true)
      setError(false)
      const groupsData = await getAllGroups(token)
      const { data } = await axios.get(url, configAxios(token))
      setLoading(false)
      setGroups(groupsData.data)
      setTableData(data.data)
      setPaginate({
        next: data.next_page_url,
        prev: data.prev_page_url,
      })
    } catch {
      setLoading(false)
      setError(true)
    }
  }

  const getTableResult = async () => {
    try {
      setLoading(true)
      setError(false)
      const groupsData = await getAllGroups(token)
      const { data } = await getAllResults({ group: filter.group, token })
      setLoading(false)
      setGroups(groupsData.data)
      setTableData(data.data)
      setPaginate({
        next: data.next_page_url,
        prev: data.prev_page_url,
      })
    } catch {
      setLoading(false)
      setError(true)
    }
  }

  const handleDownload = async () => {
    try {
      setErrDownload(false)
      setLoadDownload(true)
      const { headers, data } = await downloadExcel(filter.group, token)
      setLoadDownload(false)
      getExcel(data, headers['content-disposition'])
    } catch {
      setLoadDownload(false)
      setErrDownload(true)
    }
  }

  useEffect(getTableResult, [])

  return (
    <FitlerContext.Provider
      value={[
        filter,
        handleChangeFilter,
        tableData,
        loading,
        error,
        groups,
        paginate,
        navPaginate,
        loadDownload,
        errDownload,
        handleDownload,
        getTableResult,
      ]}
    >
      {children}
    </FitlerContext.Provider>
  )
}

export const useFilterAll = () => useContext(FitlerContext)

export const useTableFilter = () => useContext(FitlerContext)[2]

export const useLoadingFilter = () => {
  const [_, __, ___, loading, error] = useContext(FitlerContext)
  return [loading, error]
}

export const useFilter = () => {
  const [filter, setFilter] = useContext(FitlerContext)
  return [filter, setFilter]
}

export const usePaginate = () => {
  const paginate = useContext(FitlerContext)[6]
  const setPaginate = useContext(FitlerContext)[7]
  return [paginate, setPaginate]
}

export const useLoadingDownload = () => {
  const loadDownload = useContext(FitlerContext)[8]
  const errDownloasd = useContext(FitlerContext)[9]

  return [loadDownload, errDownloasd]
}
