import { Helmet } from 'react-helmet-async'
import Register, { ContainerLogin } from '../../components/Molecules/Session'

export const Login = () => (
  <>
    <Helmet>
      <title>Register</title>
    </Helmet>
    <ContainerLogin>
      <Register />
    </ContainerLogin>
  </>
)
