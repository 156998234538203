import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Stack, Heading, Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useSessionStorage } from '../../hooks/useSessionStorage'
import key from '../../config/keySessionStorage'
import { ResultFinishForm } from '../../components/Molecules/ResultFinishForm/ResultFinishForm'

export const FinishedForm = () => {
  const [results] = useSessionStorage(key.resultVocational, 'NaN')
  const { full_name = '', message = '', link = '', groups = [] } = results
  const [screenX, setScreenX] = useState(window.screen.width)

  useEffect(() => {
    const handleChange = () => setScreenX(window.screen.width)
    window.addEventListener('resize', handleChange)
    return () => window.removeEventListener('resize', handleChange)
  }, [setScreenX])

  return (
    <>
      <Helmet>
        <title>Finished vocational</title>
      </Helmet>
      <Stack
        w="100%"
        minH="100vh"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        {message !== '' && (
          <>
            <Heading textAlign="center">{message}</Heading>
            {link !== '' && (
              <Link href={link} isExternal>
                La CORHUILA ofrece estos programas para ti. Has click aquí para
                ver más
                <ExternalLinkIcon mx="2" />
              </Link>
            )}
          </>
        )}
        {groups.length > 0 && (
          <>
            <Heading p="5" textAlign="center">
              ¡Excelente {full_name}! Has terminado el test de intereses y
              aptitudes vocacionales. Los resultados indican que tu grupo
              ocupacional y las profesiones afines son:
            </Heading>
            {groups.map(
              ({ group = '', programs = [], description = '' }, index) => (
                <ResultFinishForm
                  key={index}
                  group={group}
                  programs={programs}
                  description={description}
                />
              )
            )}
          </>
        )}
        {results === 'NaN' && (
          <Heading>Aun no has completado el cuestionario</Heading>
        )}
      </Stack>
    </>
  )
}
