import styled from 'styled-components'

const FooterContent = styled.footer`
  width: 100%;
  height: 200px;
  background-image: url('/assets/test-vocacional-1.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
`

export const Footer = () => {
  return <FooterContent />
}
