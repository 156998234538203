import React, { useContext } from 'react'
import { useColorMode, useColorModeValue } from '@chakra-ui/react'

export const PrincipalContext = React.createContext({})

export const PrincipalProvider = ({ children }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const bg = useColorModeValue('white', 'gray.800')
  const bgProgress = useColorModeValue('green.300', 'green.400')

  return (
    <PrincipalContext.Provider
      value={{ colorMode, bg, bgProgress, toggleColorMode }}
    >
      {children}
    </PrincipalContext.Provider>
  )
}

export const useContextPrincipal = () => useContext(PrincipalContext)
