import {
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Button,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'

const D_F = () => {}

export const AdminTable = ({
  tableData = [],
  onClick = D_F,
  paginate = { next: null, prev: null },
  navPaginate = D_F,
  stateDownload: { loadDownload = false, errDownload = false },
}) => (
  <>
    <Table>
      {tableData.length > 0 && (
        <TableCaption>
          <Button onClick={onClick} isLoading={loadDownload}>
            {errDownload ? 'Volver a intentar' : 'Descargar información'}
          </Button>
        </TableCaption>
      )}
      <Thead>
        <Tr>
          <Th>Nombre del estudiante</Th>
          <Th>Correo Electronico</Th>
          <Th>Numero telefonico</Th>
          <Th>resultado - Ocupacion Vocacional</Th>
        </Tr>
      </Thead>
      <Tbody>
        {tableData.length > 0 &&
          tableData.map(
            (
              { full_name = '', email = '', phone_number = '', groups = [] },
              index
            ) => {
              const group = (pos) => groups[pos]?.group
              return (
                <Tr key={index}>
                  <Td>{full_name}</Td>
                  <Td>{email}</Td>
                  <Td>{phone_number}</Td>
                  <Td>
                    {groups.length > 1 ? `${group(0)} | ${group(1)}` : group(0)}
                  </Td>
                </Tr>
              )
            }
          )}
      </Tbody>
      <Tfoot>
        <Tr>
          <Th></Th>
          <Th></Th>
          <Th></Th>
          {tableData.length > 0 && (
            <>
              <Th isNumeric>
                <IconButton
                  mr="2"
                  icon={<FiArrowLeft />}
                  disabled={paginate.prev === null}
                  onClick={() => navPaginate(paginate.prev)}
                />
                <IconButton
                  icon={<FiArrowRight />}
                  disabled={paginate.next === null}
                  onClick={() => navPaginate(paginate.next)}
                />
              </Th>
            </>
          )}
        </Tr>
      </Tfoot>
    </Table>
    {tableData.length === 0 && (
      <Text textAlign="center">No existen datos guardados</Text>
    )}
  </>
)
